import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  smallMarginTopSubstitute,
  introSection,
  //buttonGroup,
} from "./subpage.module.scss";
//import { Link } from "gatsby";

const NewaNeujahrPage = () => {
  return (
    <Layout pageTitle="NeujahrNews2023" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                <p className={description}>News - Januar 2023</p>
                <h1>
                  Wir von the i-engineers wünschen Ihnen ein erfolgreiches und
                  gesundes 2023
                </h1>

                <p className="text-medium">
                  Im letzten Jahr haben wir wieder eine Reihe von Neukunden in
                  der Schweiz und in Deutschland gewonnen und zugleich
                  bestehende Kunden mit neuen Produkten und Modul-Erweiterungen
                  unterstützt. Wir sagen an dieser Stelle nochmals Herzlichen
                  Dank an alle Kunden für das entgegengebrachte Vertrauen und
                  die gute Zusammenarbeit.
                </p>

                <p className="text-medium">
                  Wir freuen uns, Sie auch in 2023 mit unseren Softwareprodukten
                  bei den Herausforderungen der Digitalisierung Ihrer
                  Unternehmen zu unterstützen.
                </p>
                <p className="text-medium">
                  Ein kurzer Blick zurück auf einige Highlights des letzten
                  Jahres:{" "}
                </p>
                <ul className="text-medium text-blue">
                  <li>
                    <a href="#neueModule">
                      {" "}
                      Launch neuer Module auf Basis der{" "}
                      <span className="italic">health-engine® &gt;</span>{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#standort">
                      Eröffnung eines weiteren Standorts in Deutschland &gt;
                    </a>
                  </li>
                  <li>
                    <a href="#CI">
                      Neuer Marktauftritt mit frischem Design &gt;
                    </a>
                  </li>
                  <li>
                    <a href="#Kunden-Feedback">
                      Kunden-Feedback mit exzellentem Ergebnis &gt;
                    </a>
                  </li>
                  <li>
                    <a href="#TIE-Talks">Anwendertreffen: TIE Talks &gt;</a>
                  </li>
                  <li>
                    <a href="#Jahresendverlosung">
                      Jahresendverlosung mit 3 Gewinnern &gt;
                    </a>
                  </li>
                </ul>

                <div id="neueModule" className={marginTopSubstitute}>
                  <h3>
                    Launch neuer Module auf Basis der{" "}
                    <span className="italic">health-engine®</span>
                  </h3>

                  <p>
                    In 2022 brachten wir 3 neue Haupt-Module erfolgreich in den
                    Markt:{" "}
                  </p>

                  <p>
                    {" "}
                    Das Modul{" "}
                    <a
                      href="https://www.tie.ch/module/#patientenportal"
                      target="_blank"
                      rel="noreferrer"
                      className="italic-blue"
                    >
                      Patientenportal
                    </a>
                    , eine digitale Begleitung für Patient:Innen vor, während
                    und nach dem Aufenthalt, mit tiefer Verzahnung in die
                    klinischen Prozesse und Dokumentation.
                  </p>

                  <StaticImage
                    src="../assets/PP-KSBL.png"
                    alt="Screenshot Patientenportal KSBL"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />

                  <div className={smallMarginTopSubstitute}>
                    <p>
                      Das Modul <span className="italic-blue">PräOp</span>, kurz
                      für „Präoperative Abklärungen“, zur digitalen Vorbereitung
                      von Anästhesie-Sprechstunden, bei der alle relevanten
                      Informationen zentral in einem Anästhesie-Dossier
                      zusammengetragen werden.
                    </p>

                    <p>
                      Und speziell für den deutschen Markt, das Modul{" "}
                      <a
                        href="https://www.tie.ch/module/#md-akte"
                        target="_blank"
                        rel="noreferrer"
                        className="italic-blue"
                      >
                        MD-Akte
                      </a>
                      , welches dem{" "}
                      <span className="text-blue">
                        {" "}
                        Fall- und Forderungsmanagement
                      </span>{" "}
                      in Krankenhäusern eine komfortable und{" "}
                      <span className="italic">automatisierte</span> Lösung für
                      die Beantwortung von Fragen seitens des{" "}
                      <span className="text-blue">Medizinischen Diensts</span>{" "}
                      bereitstellt, inklusive einer digitalen Schnittstelle zum
                      LE-Portal.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className={col12} id="standort">
                <h3>Eröffnung eines weiteren Standorts in Deutschland</h3>

                <p>
                  Wir freuen uns mit den neuen Kolleg:Innen über die Eröffnung
                  unseres{" "}
                  <span className="text-blue">
                    Standorts in Münster, Westfalen
                  </span>
                  . Das Deutschlandgeschäft wird auch in 2023 stark ausgebaut
                  und wir planen hierzu einen weiteren Standort im Süden der
                  Republik.
                </p>
              </div>

              <div className={col12} id="CI">
                <h3>Neuer Marktauftritt mit frischem Design</h3>

                <StaticImage
                  src="../assets/neues-ci.jpg"
                  alt="Screenshot Patientenportal KSBL"
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />

                <p className={smallMarginTopSubstitute}>
                  Zur DMEA 2022 stellten wir unseren{" "}
                  <span className="text-blue">
                    neuen, modernen Auftritt vor, mit verändertem Logo,
                    lebendigen Farben und agilen Design-Elementen.
                  </span>{" "}
                  Gerade die überarbeiteten Knetfiguren spiegeln dabei
                  Kernaspekte unserer Firmenphilosophie wider: die Elemente
                  bewegen sich dynamisch schnell, scheinen federleicht und
                  halten trotzdem stark zusammen. Auf unser neues Corporate
                  Design haben wir viel positives Feedback erhalten. Besten Dank
                  dafür.
                </p>
              </div>

              <div className={col12} id="Kunden-Feedback">
                <h3>Kunden-Feedback mit exzellentem Ergebnis</h3>
                <p>
                  Sicherlich das Top Highlight des letzten Jahres: Denn es ging
                  hier um Ihre Meinung. Und wir sind über das Ergebnis sehr
                  erfreut und dankbar. Bei unserem Service Desk können Kunden
                  nach gelöster Service Anfrage, ihre Zufriedenheit mit bis zu 5
                  Sternen und Kommentar kundtun. Im Jahr 2022 kommen wir auf
                  eine{" "}
                  <span className="text-blue">
                    durchschnittliche Zufriedenheit von 4.9/5 Sternen
                  </span>
                  . Ein Rekordergebnis. Nachfolgend einige Beispiele von
                  Kommentaren unserer Kunden:
                </p>

                <StaticImage
                  src="../assets/KundenFeedback.jpg"
                  alt="Screenshot Patientenportal KSBL"
                  placeholder="none"
                  layout="fullWidth"
                  formats={["auto", "webp", "avif"]}
                />
              </div>

              <div className={col12} id="TIE-Talks">
                <h3>Anwendertreffen: TIE Talks </h3>
                <p>
                  Der Austausch mit und zwischen unseren Kunden ist uns sehr
                  wichtig. Daher haben wir ein neues Forum geschaffen, den{" "}
                  <span className="text-blue">TIE Talk</span>, bei dem wir
                  verschiedene Kunden aus dem Krankenhaus-Sektor zum
                  gegenseitigen Austausch zusammenbringen. In 2022
                  veranstalteten wir zwei TIE Talks: In Deutschland trafen wir
                  uns in Hamburg und die{" "}
                  <span className="text-blue">
                    Immanuel Albertinen Diakonie
                  </span>{" "}
                  präsentierte bei dieser Gelegenheit, wie sie die{" "}
                  <span className="italic-blue">health-engine®</span> als{" "}
                  <span className="italic-blue">
                    Interoperabilitätsplattform
                  </span>{" "}
                  einsetzen. In der Schweiz stellten das{" "}
                  <span className="text-blue">Universitätsspital Basel</span>{" "}
                  und das{" "}
                  <span className="text-blue">Kantonsspital Graubünden</span> in
                  einem gemeinsamen TIE Talk das{" "}
                  <span className="italic-blue">health-engine®</span> Modul{" "}
                  <a
                    href="https://www.tie.ch/module/#art"
                    target="_blank"
                    rel="noreferrer"
                    className="italic-blue"
                  >
                    {" "}
                    ART{" "}
                  </a>{" "}
                  im Einsatz vor, und tauschten sich mit interessierten
                  Spitälern darüber aus. Die Ergebnisse und das Feedback an
                  beiden TIE Talks waren durchwegs positiv und sowohl die Kunden
                  als auch wir haben viele neue Inspirationen und Ideen
                  mitgenommen. Besten Dank an dieser Stelle nochmals an die
                  Immanuel Albertinen Diakonie, das Universitätsspital Basel und
                  das Kantonsspital Graubünden.
                </p>

                <p>
                  Besonders sehenswert ist in diesem Zusammenhang das Interview
                  des Krankenhaus-IT Journal zum TIE Talk in Hamburg mit{" "}
                  <span className="text-blue">Andreas Rygol</span>,
                  <span className="italic">
                    Leiter Konzernbereich Informationstechnologie und
                    Medizintechnik der Immanuel Albertinen Diakonie gGmbH:{" "}
                  </span>
                </p>

                <a
                  href="https://www.youtube.com/watch?v=ZkFEgx0OW9A"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    src="../assets/Interview-Video-Hero-Andreas-vs1.jpg"
                    alt="Interview mit Andreas Rygol"
                    placeholder="none"
                    layout="fullWidth"
                    formats={["auto", "webp", "avif"]}
                  />
                </a>

                <p className={smallMarginTopSubstitute}>
                  {" "}
                  Besten Dank nochmals an Herrn Rygol. Aufgrund des großen
                  Erfolgs wird es die TIE Talks auch in 2023 sowohl in
                  Deutschland als auch in der Schweiz geben, und wir freuen uns
                  bereits auf spannende und ergebnisreiche Gespräche.
                </p>
              </div>

              <div className={col12} id="Jahresendverlosung">
                <h3>Last but not Least: We have 3 Winners.</h3>

                <p>
                  An unserer{" "}
                  <span className="text-blue">
                    Jahresendverlosung für Kunden
                  </span>{" "}
                  gab es 2022 eine Rekordbeteiligung. Aus allen Einsendungen
                  haben wir 3 Gewinner gezogen. Die glücklichen Firmen sind: Das{" "}
                  <span className="text-blue">
                    Kantonsspital Graubünden, die Klinikgruppe Hirslanden
                  </span>{" "}
                  sowie die{" "}
                  <span className="text-blue">Gesundheitsholding Lüneburg</span>
                  . Alle 3 erhalten jeweils einen halben Tag Dienstleistung von
                  unserem Konfig-Team geschenkt.
                </p>
              </div>

              <div className={col12}>
                <h3>Vielen Dank für Ihr Vertrauen.</h3>

                <p>
                  Das gesamte Team von{" "}
                  <span className="text-blue">the i-engineers</span> freut sich
                  sehr auf die Zusammenarbeit mit Ihnen in 2023. Wir haben
                  hierzu neue, innovative Produkte, einen weiteren Standort in
                  Deutschland und viele große und kleine Events geplant, an
                  denen wir uns mit Ihnen austauschen können.{" "}
                </p>

                <p>
                  Ihr Team der{" "}
                  <span className="text-blue">the i-engineers</span>
                </p>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NewaNeujahrPage;
